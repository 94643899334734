body {
    background-color:#1f2841;
}
html,
body,
header,
.intro-section {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  #particles-js {
      height: 100%;
  }
}
@media (min-width: 560px) and (max-width: 740px) {
  html,
  body,
  header,
  .intro-section {
    height: 50vh;
  }
}
.theme-skin { 
    .mask {
        &.rgba-gradient {
            background: linear-gradient(45deg, rgba(255, 238, 0, 0.4), rgba(15, 0, 44, 0.8));
        }
    }
    .brand-text {
      color: $brand-color;
    }
    a {
      color: $brand-color;
      &:hover {
        color: grey;
      }
      &.fancybox {
        cursor: zoom-in;
        .mask {
          cursor: zoom-in;
        }
      }
    }
    //list
    ul.fa-ul {
        li {
            margin-bottom: 20px;
        }
    }
}

.wow {
  visibility: hidden;
}
// Your custom styles
@import "custom/_animated_logo";
@import "custom/_footer";
@import "custom/_fancybox";
@import "custom/_header";
@import "custom/_navbar";
@import "custom/_slickslides";
//@import "custom/_carousel";
//@import "custom/_forms";
//@import "custom/_kontakt";
//@import "custom/_omoss";
//@import "custom/_personel";
//@import "custom/_sidebar";
//@import "custom/_tjanster";
//@import "custom/cars/_all";