@mixin letter-anim($delay, $duration) {
    $name: letter-#{unique-id()};

    @keyframes #{$name} {
        0% {
            stroke: white;
            fill: transparent;
        }
        75% {
            stroke-dashoffset: 0;
            fill: transparent;
        }
        100% {
            stroke-width: 0;
            fill: rgba(255,255,255,1);
        }

    }

    animation-name: $name;
    animation-duration: $duration;
    animation-delay: $delay;
    animation-fill-mode: forwards;
    animation-timing-function: ease;

}

@mixin fill-anim($color, $delay) {
    $name: fill-#{unique-id()};

    @keyframes #{$name} {
      from {fill: transparent;}
        to {fill: $color;}
    }

    animation-name: $name;
    animation-duration: 0.7s;
    animation-delay: $delay;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
}
#logoanim {
    width:100%;
    filter: drop-shadow(rgb(0, 0, 0) 0px 0px 5px);
    .lines {
        path {
            stroke: white;
            stroke-width: 1;
            &:nth-child(1) {
                stroke-dasharray: 259;
                stroke-dashoffset: 259;
                @include letter-anim(0s, 3s);
            }
            &:nth-child(2) {
                stroke-dasharray: 165;
                stroke-dashoffset: 165;
                @include letter-anim(0s, 3s);
            }
            &:nth-child(3) {
                stroke-dasharray: 52;
                stroke-dashoffset: 52;
                @include letter-anim(0s, 3s);
            }
            &:nth-child(4) {
                stroke-dasharray: 198;
                stroke-dashoffset: 198;
                @include letter-anim(0.3s, 3s);
            }
            &:nth-child(5) {
                stroke-dasharray: 255;
                stroke-dashoffset: 255;
                @include letter-anim(0.6s, 3s);
            }
            &:nth-child(6) {
                stroke-dasharray: 283;
                stroke-dashoffset: 283;
                @include letter-anim(1s, 3s);
            }
            &:nth-child(7) {
                stroke-dasharray: 198;
                stroke-dashoffset: 198;
                @include letter-anim(1.4s, 3s);
            }
            &:nth-child(8) {
                stroke-dasharray: 407;
                stroke-dashoffset: 407;
                @include letter-anim(1.7s, 3s);
            }
        }
    }
    .dot path {
        @include fill-anim(#ED1C24, 3.2s);
    }
    .bgedge {
        //@include fill-anim(rgba(255,255,255,1), 3.5s);
        stroke-dasharray: 2242;
        stroke-dashoffset: 2242;
        @include letter-anim(3.5s, 4s);
    }
}