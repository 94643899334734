// Your custom skin
// Skins
$skins: () !default;
$skins: map-merge((
  "theme": (
    "skin-primary-color":      #000000,
    "skin-navbar":             rgb(31, 40, 65),
    "skin-footer-color":       rgb(31, 40, 65),
    "skin-accent":             #ff0000,
    "skin-flat":               #222,
    "skin-sidenav-item":       #ff0000,
    "skin-sidenav-item-hover": #ff0000,
    "skin-gradient-start":     #000,
    "skin-gradient-end":       #616161,
    "skin-mask-slight":        rgba($black, .5),
    "skin-mask-light":         rgba($black, .65),
    "skin-mask-strong":        rgba($black, .8),
    "skin-sn-child":           #ff0000,
    "skin-btn-primary":        #ff0000,
    "skin-btn-secondary":      #0f478a,
    "skin-btn-default":        #222222,
    "skin-text":               $white
  )
), $skins);