$slick-font-family: "fontawesome";
$slick-prev-character: "\f053";
$slick-next-character: "\f054";
$slick-dot-character: "\f111";
$slick-loader-path: "../img/overlays/";
@import "../node_modules/slick-carousel/slick/slick.scss";
@import "../node_modules/slick-carousel/slick/slick-theme.scss";


// section#bil {
//     @include media-breakpoint-up(lg) {
//         .price-overlay {
//             right: 58px; // if max-width 1024 on slick-slide
//         }
//     }
// }

.slider-for {
    .slick-slide {
        .image {
            width: 100%;
            padding-bottom: 66.5%;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
        a {
            .slider-fullscreen {
                position: absolute;
                right: 0;
                bottom: 0;
                background: rgb(51, 51, 51);
                opacity: 0.7;
                padding: 5px 10px;
                color: white;
                z-index: 1000;
            }
        }
    }
}
.slider {
    .slick-list {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    }
}
.slidernav {
    .slick-slide {
        position: relative;
        max-width: 100%;
        height: auto!important;
        margin: 0px;
        .image {
            background-size: cover;
            width: 100%;
            padding-bottom: 66.5%;
            background-position: center;
            background-repeat: no-repeat;
        }
        opacity: $slick-opacity-default;
        &.slick-current {
            opacity: $slick-opacity-on-hover;
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
            .image:before {
                background: rgb(242, 76, 90);
                height: 4px;
                width: 100%;
                left: 0;
                right: 0;
                bottom: 0;
                content: "";
                position: absolute;
            }
        }
    }
}
.slick-slider {
    a.prev, a.next {
      position: absolute;
      top: 0;
      bottom: 0;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 15%;
      color: #fff;
      text-align: center;
      opacity: .5;
      cursor: pointer;
      text-decoration: none;
      transition: all .2s ease-in-out;
      z-index: 100;
      i:before, i:before {
        font-size: 3rem;
      }
      &:hover {
        color: white;
        opacity: 1;
        text-shadow: 2px 2px 2px #000;
        transition: all .2s ease-in-out;
      }
    }
    a.prev {
      left:0;
    }
    a.next {
      right: 0;
    }
    .slick-dots {
      bottom: 0;
      li button:before {
        color: #ffffff;
      }
    }
}
