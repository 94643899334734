body {
  .navbar {
    /* Navbar animation */
    &:not(.top-nav-collapse) {
        background-color: transparent;
    }
    
    @media (max-width: 768px) {
        &:not(.top-nav-collapse) {
            background: #7d8488;
        }
    }
    
    @media (min-width: 769px) {
        &:not(.top-nav-collapse) {
            box-shadow: none;
        }
    }
    /* Adding color to the Navbar on mobile */
    &.scrolling-navbar {
        background-color: rgba(31, 40, 65, 0);
        &.top-nav-collapse {
            background-color: rgba(31, 40, 65, 1);
        }
    }
    &:not(.top-nav-collapse) {
        box-shadow: none;
    }

    .navbar-nav {
        .nav-item {
            .nav-link {
                font-weight: 600;
                text-transform: uppercase;
                //font-size: 0.8rem;
            }
            &.active {
                .nav-link {
                    background-color: transparent!important;
                    border-bottom: 4px solid map-get(map-get($skins, theme),skin-primary-color);
                }
            }
        }
        &.nav-flex-icons {
            .nav-item {
                font-size: 1.3rem;
                padding: 0 3px;
            }
        }
    }
    .navbar-brand {
        max-width: 250px;
        color: white;
        img {
            -webkit-filter: drop-shadow(12px 12px 7px rgba(0,0,0,0.5));      
            filter: drop-shadow(12px 12px 7px rgba(0,0,0,0.5));
        }
    }
  }
}
