.header-logo {
  display: block;
  max-width: 281px;
  height: 75px;
  text-indent: -9999px;
  //background-image: url(../../img/logos/logotype2.png);
  //background-position: center;
  //background-size: contain;
  //background-repeat: no-repeat;
}
.top-logos {
  img {
    @extend .img-fluid;
  }
}

body {
    header {
        height: 30vh;
    }
    &.index {
        header {
            height: 100vh;
            @include media-breakpoint-up(md) {
                height: 70vh;
            }
            .view {
                height: 100%;
                .intro-info-content {
                    text-shadow: 2px 2px 3px #000;
                }
                .bgimage {
                    background-size: cover;
                    background-position: center;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    &.blur {
                        transform: scale(1.05);
                        filter: blur(5px);
                    }
                }
                .intro-logo {
                    padding: 5px;
                    background-color: white;
                    border-radius: 5px;
                }
            }
        }
    }
}